import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBannerMining'
import Footer from "../components/App/Footer"
//import TestimonialsStyleOne from '../components/Testimonials/TestimonialsStyleOne'
import TradingDetail from '../components/Testimonials/Trading'

const Trading = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Trading" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Trading" 
            />
            {/*<TestimonialsStyleOne />*/}
            <TradingDetail />
            <Footer />
        </Layout>
    );
}

export default Trading